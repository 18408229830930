<template>
	<div class="trialroute">
		<!-- 导航栏 -->
		<van-nav-bar
		class="nav_bar_color"
		title="试乘试驾路线"
		left-text="返回"
		left-arrow
		@click-left="onClickLeft"
		right-arrow
		:right-text="(!info.scsj_route_id && !shareType)?'分享':''"
		@click-right="show = true"
		fixed
		z-index="11"
		placeholder 
		:border="false"
		/>
		
		<!-- 车型 -->
		<div class="car__model" v-if="!info.scsj_route_id">
			<img class="car__model__logo" :src="info.car_images" mode="widthFix">
			<div class="car__model__con">{{info.model_name}} {{info.collocation_name}}</div>
			<div class="car__model__btn">刷新</div>
		</div>
		
		<div class="car__model_gou" v-if="!info.scsj_route_id"></div>

		<!-- 路线 -->
		<div class="routes" v-if="!info.scsj_route_id">
			<van-swipe
				class="" 
				@change="handRouteEdit"
			>
				<van-swipe-item v-for="item in routes" :key="item.id">
					<img class="swipe_img" :src="item.route_image" mode="aspectFit">
				</van-swipe-item>
			</van-swipe>
		</div>
		<div class="bj_img" v-if="info.scsj_route_id">
			<img :src="routeInfo.route_image">
		</div>
		
		<!-- 体验点-->
		<div class="groups" v-if="!info.scsj_route_id">
			<div
				:class="['group', item.id == group_id ? 'group--active' : '']"
				v-for="item,key in groups"
				:key="item.id"
				@click="group_id = item.id"
			>
				<img v-if="key == 0" src="@/assets/drivroute/icon_che1.png">
				<img v-if="key == 1" src="@/assets/drivroute/icon_che2.png">
				<img v-if="key == 2" src="@/assets/drivroute/icon_che3.png">
				<!-- <div class="group__title">
					{{item.name}}
				</div> -->
			</div>
		</div>
		
		<!-- 选项 -->
		<div class="opts" v-if="!info.scsj_route_id && item.showOpt" v-for="item,key in opts" :key="item.scsj_experience_node_id">
			<div class="opts__title">
				{{item.node_name}}
			</div>
			<div class="opts__con">
				<div
					class="opt"
					v-for="itemContent in item.res"
					:key="itemContent.id"
					@click="handPoint(itemContent.id)"
					v-if="key == 0 || itemContent.recommend == 0"
				>
					<div class="opt__row1">
						<div class="opt__radio" v-if="point_ids.indexOf(itemContent.id) == -1"></div>
						<div class="opt__radioed" v-if="point_ids.indexOf(itemContent.id) > -1"></div>
						<div class="opt__name" :class="{'isyellow':item.node_name == '推荐体验项目'}">
							{{itemContent.name}}
						</div>
					</div>
					<div class="opt__row2" v-if="itemContent.star != null && itemContent.star.length != null">
						*该配置仅在{{ itemContent.star.map(v=>v.name).join(',') }}中
					</div>
					<div class="opt__row2" v-if="itemContent.explain && itemContent.explain!=null">
						{{ itemContent.explain }}
					</div>
				</div>
			</div>
		</div>
		<div class="opts" v-if="info.scsj_route_id" v-for="item,key in routePoints" :key="item.scsj_experience_node_id">
			<div class="opts__title">
				{{item.node_name}}
			</div>
			<div class="opts__con">
				<div
					class="opt"
					v-for="itemContent in item.res"
					:key="itemContent.id"
				>
					<div class="opt__row1">
						<div class="opt__radioed"></div>
						<div class="opt__name" :class="{'isyellow':item.node_name == '推荐体验项目'}">
							{{itemContent.name}}
						</div>
					</div>
					<div class="opt__row2" v-if="itemContent.star != null && itemContent.star.length != null">
						*该配置仅在{{ itemContent.star.map(v=>v.name).join(',') }}中
					</div>
					<div class="opt__row2" v-if="itemContent.explain && itemContent.explain!=null">
						{{ itemContent.explain }}
					</div>
				</div>
			</div>
		</div>
		
		<!-- 保存 -->
		<div style="height: 72px;"></div>
		<div class="savebtn" v-if="!info.scsj_route_id">
			<div @click="handSave">保存</div>
		</div>

		<van-overlay style="display: flex;align-items: center;justify-content: center;" z-index="100000" :show="show" @click="show=false">
			<div class="wrapper" style="color: #fff;" @click.stop>
				<div class="abs" @click="show = false">关闭<img src="@/assets/trial/icon_guambi.png" alt=""></div>
				<div class="title">试乘试驾路线规划</div>
				<div class="concat">请顾客先扫描下方企业微信二维码添加好友</div>
				<div class="qrimg" :class="{'isborder':wechat_qr_image == '' || wechat_qr_image == null}">

					<div @click="gouser" v-if="wechat_qr_image == '' || wechat_qr_image == null" class="user_wxupload_upload_content">
						<span class="titleupload">尚未上传</span>
						<span class="titleupload">企业微信二维码</span>
						<span class="concatupload">点击设置</span>
					</div>
					<img v-else :src="wechat_qr_image" >
				</div>
				<div class="botton" @click="onshare">通过企业微信发送</div>
			</div>
		</van-overlay>
	</div>
</template>

<script>
import {getsignature} from '@/utils/utils'
export default {
	data() {
		return {
			wechat_qr_image:'',
			show:false,
			shareType: false,
			info: {
				number: '',
			},
			route_id: 0,
			routes: [],
			
			group_id: 0,
			groups: [],
			opts: [],
			point_ids: [],
			
			routeInfo: {},
			routePoints: []
		};
	},
	mounted() {
		if (this.$route.query?.number) this.info.number = this.$route.query?.number
		if (this.$route.query?.share) this.shareType = true
		this.getTrialInfo()
		this.getUserInfo()

	},
	watch: {
	
		group_id(e) {
			this.opts.length = 0
			this.point_ids.length = 0
			const groups =  this.groups.filter(v => v.id == this.group_id)
			if (groups.length < 1) {
				return
			}
			const group = groups[0]
			const check_point_ids = group.scsj_experience_point_ids.split(',')
			const optRecommend = {
				scsj_experience_node_id: 0,
				node_name: '推荐体验项目',
				showOpt: true,
				res: [],
			}
			group.all_experience_point.forEach(v => {
				v.showOpt = false
				v.res.forEach(vr => {
					if (check_point_ids.indexOf(vr.id + '') > -1) this.point_ids.push(vr.id)
					if (vr.recommend == 1) {
						optRecommend.res.push(vr)
					} else {
						v.showOpt = true
					}
				})
				this.opts.push(v)
			})
			if (optRecommend.res.length > 0) this.opts.unshift(optRecommend)
		}
	},
	methods: {
			// 用户详情
		async getUserInfo(){
			const {data: result} = await this.$http.post('/api/user/get_userinfo_1_5_1')
			if (result.code == 1) {
				this.wechat_qr_image = result.data.res.wechat_qr_image
			}
		},
		// 修改二维码
		gouser(){
			this.$router.push({
				path: `/useredit`
			})
		},
		onshare(){
			const url = localStorage.getItem('share')
			this.$wx.invoke(
						"shareAppMessage", {
							title: '试乘试驾方案定制', // 分享标题
							desc: '', // 分享描述
							link: url+'/scsj/scsjtydxz/?number=' + this.info.number, // 分享链接；在微信上分享时，该链接的域名必须与企业某个应用的可信域名一致
							imgUrl: 'https://hm-h5-1.oss-cn-hangzhou.aliyuncs.com/imm-2212-scsj/logo.jpeg', // 分享图标
							enableIdTrans: 0, // 是否开启id转译，不填默认为0
						}, function(res) {
								if (res.err_msg == "shareAppMessage:ok") {
									this.$toast.success('分享成功')						//正确处理
								}else {
									getsignature()					//错误处理
								}
						}
					);
			this.show=false
		},
		// 选中体验点
		handPoint(e) {
			const k = this.point_ids.indexOf(e)
			if (k > -1) {
				this.point_ids.splice(k, 1)
			} else {
				this.point_ids.push(e)
			}
		},
		
		// 切换路线
		handRouteEdit(e) {
			this.route_id = this.routes[e].id
		},
		
		// 保存
		async handSave(e) {
			if (this.point_ids.length < 12) {
				return this.$toast.fail('请至少选择12项体验项目', 'error')
			}
			const {data: result} = await this.$http.post(this.shareType ? '/api/scsj/h5_edit_customer_route_point' : '/api/scsj/edit_customer_route_point_1_5', {
				number: this.info.number,
				route_id: this.route_id,
				group_id: this.group_id,
				point_ids: this.point_ids.sort().join(',')
			})
			if (result.code == 1) {
				if (this.shareType) {
					this.getTrialInfo()
				} else {
					this.$toast.success('保存成功')
					this.$router.go(-1)
				}
				
				
			}
		},
		
		// 试驾详情
		async getTrialInfo(e) {
			const {data: result} = await this.$http.post('/api/scsj/get_scsj_info_1_7', {
				number: this.info.number
			})
			if (result.code == 1) {
				this.info = { ...this.info, ...result.data.res }
				this.info.car_images = this.serverUrl(this.info.car_images)
				if (this.info.scsj_route_id) {
					this.getRouteInfo()
				} else {
					if(!this.shareType){
						this.show=true
					}
					
					this.getGroupList()
					this.getRouteList()
				}
				
			}
		},
		
		// 路线详情
		async getRouteInfo(e) {
			const {data: result} = await this.$http.post('/api/scsj/get_scsj_preview', {
				number: this.info.number
			})
			if (result.code == 1) {
				this.routeInfo = { ...this.routeInfo, ...result.data.res.route }
				this.routeInfo.route_image = this.serverUrl(this.routeInfo.route_image)
				this.routePoints.length = 0
				result.data.res.points.experience_point.forEach(v => {
					this.routePoints.push(v)
				})
			}
		},
		
		// 路线列表
		async getRouteList(e) {
			const {data: result} = await this.$http.post('/api/scsj/get_scsj_route_1_5_1', {})
			if (result.code == 1) {
				this.routes.length = 0
				this.point_ids.length = 0
				result.data.res.forEach(v => {
					v.route_image = this.serverUrl(v.route_image)
					if (this.route_id == 0) {
						this.route_id = v.id
					}
					this.routes.push(v)
				})
			}
		},
		
		// 体验列表
		async getGroupList(e) {
			const {data: result} = await this.$http.post('/api/scsj/get_scsj_customer_group_1_7_2', {
				model_id: this.info.scsj_car_model_id,
				collocation_id: this.info.scsj_car_collocation_id,
				if_cruise: this.info.if_cruise,
				store_id: this.info.scsj_store_id
			})
			if (result.code == 1) {
				this.groups.length = 0
				result.data.res.forEach(v => {
					if (this.group_id == 0){
						this.group_id = v.id
					}
					this.groups.push(v)
				})
				if(this.info.scsj_invitation != null && this.info.scsj_invitation.group_id != null){
					this.group_id = this.info.scsj_invitation?.group_id
				}
			}
		},
	}
}
</script>

<style lang="scss" scoped>
.isborder{
	border: 1px solid #000 !important;
}
.wrapper{
	width: 602px;
	height: 812px;
	position: relative;
	background: url(http://hm-h5-1.oss-cn-hangzhou.aliyuncs.com/imm-2212-scsj/tkbj.png);
	background-size: 100% 100%;
	background-repeat: no-repeat;
	color: #191919;
	background-color: #000;
	
	padding: 48px;
	box-sizing: border-box;
	.botton{
		margin-top: 70px;
		width: 100%;
		height: 92px;
		background: #FA0037;
		line-height: 92px;
		text-align: center;
		font-size: 36px;
		font-family: HYQiHeiY3-45;
		font-weight: normal;
		
		color: #ECECEC;
	}
	.qrimg{
		
		.user_wxupload_upload_content{
			display: flex;
			flex-direction: column;
			height: 100%;
			width: 100%;
			align-items: center;
			width: 236px;
			height: 236px;
			padding: 60px 0 0 0;
			top: 0;
			left: 0;
			img{
				width: 48px;
				height: 48px;
			}
			.titleupload{
				font-size: 28px;
				font-family: HYQiHeiY3-45;
				opacity: 0.4;
				font-weight: normal;
				color: #101010;
				margin-top: 10px;
				line-height: 28px;
			}
			.concatupload{
				font-size: 44px;
				margin-top: 40px;
				font-family: HYQiHeiY3-45;
				font-weight: normal;
				color: #101010;
				line-height: 40px;
			}
		}
		margin: 40px auto 0;
		width: 320px;
		height: 320px;
		display: flex;
		justify-content: center;
		align-items: center;
		border: 1px solid #fff;
		&__btn{
			&__row1{
				font-size: 32px;
				font-family: HYQiHeiY3-45;
				color: #9B9B9B;
				line-height: 36px;
			}
			&__row2{
				margin-top: 10px;
				font-size: 32px;
				font-family: HYQiHeiY3-45;
				color: #fff;
				line-height: 36px;
			}
		}
		img{
			width: 100%;
			height: 100%;
		}
	}
	.concat{
		text-align: center;
		padding: 20px 0;
		font-size: 28px;
		font-family: HYQiHeiY3-45;
		font-weight: normal;
		color: #919191;
		line-height: 28px;
	}
	.title{
		text-align: center;
		padding: 20px;
		font-size: 40px;
		font-family: HYQiHeiY3-45;
		font-weight: normal;
		color: #101010;
		line-height: 40px;
	}
	.abs{
		position: absolute;
		top: 20px;
		right: 20px;
		font-size: 32px;
		font-family: HYQiHeiY3-45;
		display: flex;
		align-items: center;
		font-weight: normal;
		color: #818181;
		line-height: 32px;
		img{
			margin-left: 10px;
			width: 32px;
			height: 32px;
		}
	}
}
.car__model_gou{
	height: 133px;
}
.car__model{
	position: fixed;
	background: #191919;
	z-index: 99;
	top: 80px;
	width: 100%;
	box-sizing: border-box;
	height: 136px;
	display: flex;
	align-items: center;
	padding: 0 40px;
	&__logo{
		width: 200px;
		margin-right: 20px;
	}
	&__con{
		font-size: 40px;
		font-family: HYQiHeiY3-45;
		color: #FFFFFF;
	}
	&__btn{
		position: absolute;
		right: 40px;
		top: 37px;
		width: 104px;
		background: #FA0037;
		font-size: 32px;
		font-family: HYQiHeiY3-55;
		color: #FFFFFF;
		text-align: center;
		line-height: 62px;
	}
}
.groups{
	margin: 40px auto 0;
	width: 670px;
	display: flex;
	flex-wrap: nowrap;
	.group{
		flex: 1;
		position: relative;
		width: 200px;
		height: 200px;
		margin-right: 14px;
		border: 2px solid rgba(255, 255, 255, 0);
		&__title{
			position: absolute;
			left: 0;
			top: 87px;
			width: 100%;
			font-size: 40px;
			font-family: HYQiHeiY3-55;
			color: #FFFFFF;
			text-align: center;
			line-height: 40px;
		}
		img{
			width: 100%;
			height: 100%;
		}
	}
	.group:nth-child(3n){
		margin-right: 0;
	}
	.group--active{
		border: 2px solid #FA0037;
	}
}
.opts{
	margin: 40px auto 0;
	width: 670px;
	&__title{
		font-size: 36px;
		font-family: HYQiHeiY3-55;
		color: #FFFFFF;
		line-height: 36px;
		margin-bottom: 30px;
	}
	&__con{
		background: #222;
		padding: 60px 38px;
	}
	.opt{
		width: 100%;
		margin-bottom: 70px;
		&__row1{
			display: flex;
		}
		&__radio{
			width: 32px;
			height: 32px;
			border: 1px solid #FFFFFF;
			border-radius: 50%;
		}
		&__radioed{
			width: 32px;
			height: 32px;
			border: 1px solid #F3C846;
			border-radius: 50%;
			position: relative;
		}
		&__radioed::after{
			content: '';
			position: absolute;
			top: 8px;
			left: 7px;
			width: 16px;
			height: 16px;
			background: #F3C846;
			border-radius: 50%;
		}
		&__name{
			width: 550px;
			margin-left: 20px;
			font-size: 36px;
			font-family: HYQiHeiY3-45;
			color: #FFFFFF;
			line-height: 32px;
		}
		&__row2{
			margin-top: 30px;
			padding-left: 52px;
			font-size: 28px;
			font-family: HYQiHeiY3-45;
			color: #919191;
			line-height: 40px;
		}
	}
	.opt:last-child{
		margin-bottom: 0;
	}
}
.savebtn{
	background: #191919;
	position: fixed;
	bottom: 0;
	padding: 22px 40px;
	box-sizing: border-box;
	padding-bottom: calc(constant(safe-area-inset-bottom) + 22px);
	padding-bottom: calc(env(safe-area-inset-bottom) + 22px);
	width: 100%;
	div{
		width: 100%;
		background: #FA0037;
		text-align: center;
		line-height: 92px;
		font-size: 36px;
		font-family: HYQiHeiY3-55;
		color: #FFFFFF;
	}
}

.isyellow{
	color: #F3C846 !important;
}
.bj_img{
	width: 750px; 
	height: 559px;
	img{
		width: 100%; 
	}
}
.swipe_img{
	width: 750px;
	height: 559px;
}
</style>
